<template>
  <section class="payment-output-page">
    <b-container>
      <b-row>
        <b-col lg="12">
          <h3>{{ $t("payment_cancel") }}</h3>
          <div class="order-number">
            {{ $t("success_order_number") }}: {{ "#" + transactionId }}
          </div>
          <!-- <div class="order-number" v-if="transactionId != null">
            {{ $t("transaction") }}: {{ "#" + transactionId }}
          </div> -->
          <div class="note">
            <label>{{ $t("payment_cancel_msg") }}</label>
          </div>
          <div class="continue">
            <b-link to="/">
              <b-icon icon="chevron-left"></b-icon>
              {{ $t("continue_shopping") }}</b-link
            >
          </div>
          <!-- <div class="email">
            <label>E-mail adres: example@email.com</label>
          </div> -->
          <b-link class="c-btn c-btn-green" to="/account">{{
            $t("account_create")
          }}</b-link>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
// import SidebarLinks from "@/base/core/components/common/SidebarLinks.vue";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "PaymentSuccess",
  components: {
    // SidebarLinks,
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.$t("payment_cancel") },
        { name: "keywords", content: this.$t("payment_cancel") },
        { name: "description", content: this.$t("payment_cancel") },
      ],
      title: this.$t("payment_cancel"),
    };
  },
  computed: {
    transactionId() {
      if (typeof this.$store.state.route.query.transactionid != "undefined") {
        return this.$store.state.route.query.transactionid;
      }
      return null;
    },
  },
  mounted() {
    const bcrumb = { current: "success", routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
    Logger.debug("router", "mounted", this.$store.state.route)();
  },

  data: () => ({
    sidebarData: {
      heading: "Categorie",
      options: [
        { displayName: "Gentlemen", linkTo: "#", active: false },
        { displayName: "Women", linkTo: "#", active: false },
        { displayName: "Bicycle clothing sets", linkTo: "#", active: false },
        { displayName: "Children", linkTo: "#", active: false },
        { displayName: "Cycling shoes", linkTo: "#", active: false },
        { displayName: "For your bike", linkTo: "#", active: false },
        { displayName: "For your body", linkTo: "#", active: false },
        { displayName: "Brands", linkTo: "#", active: false },
        { displayName: "Cycling books", linkTo: "#", active: false },
        { displayName: "Gift voucher", linkTo: "#", active: false },
      ],
    },
  }),
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 16px;
}

ul {
  li {
    padding-left: 15px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 1px;
      width: 4px;
      height: 4px;
      background: #000;
      border-radius: 100%;
    }
  }
}

.mb-lg-27 {
  margin-bottom: 27px;
}

.font-weight-medium {
  font-weight: 500;
}
</style>
